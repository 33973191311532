import React, { useRef, useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsEyeFill, BsEyeSlashFill, BsBugFill, BsVirus, BsQuestionCircleFill, BsBox2Fill, BsRecycle, BsCheckCircleFill, BsFillExclamationCircleFill } from "react-icons/bs";

function formatDate(dateString) {
	const dateObject = new Date(dateString);
	const options = { year: 'numeric', month: 'short', day: 'numeric' };
	const formattedDateParts = dateObject.toLocaleDateString('en-US', options).split(' ');
	const formattedDate = `${formattedDateParts[0]} ${formattedDateParts[1]} ${formattedDateParts[2]}`;
	return formattedDate; // Returns the formatted date string
}

const AnnotationComponent = ({annotation, labelRecognition, isEditing, toggleAnnotationVisibility, deleteOrRestore}) => {
	return (
		<Card border="success" className="w-100">
			<Card.Header style={{ fontSize: '0.9rem' }}>
				<Container style={{ padding: 0 }}>
					<Row className="align-items-center">
						{annotation.label_type === "pest" ? (
							<Col className="d-flex align-items-center justify-content-start">
								<BsBugFill size={22} className="me-1 text-warning" /> {labelRecognition.recLabel}
							</Col>) 
						: annotation.label_type === "disease" ? (
							<Col className="d-flex align-items-center justify-content-start">
								<BsVirus size={22} className="me-1 text-success" /> {labelRecognition.recLabel}
							</Col>)
						: (
							<Col className="d-flex align-items-center justify-content-start">
								<BsQuestionCircleFill size={22} className="me-1 text-danger" /> {labelRecognition.recLabel}
							</Col>)
						}
						{
							isEditing
							?<Col xs="auto" className="d-flex align-items-center">
								{annotation.is_deleted
								? <BsRecycle size={22} className="text-success" onClick={() => deleteOrRestore(annotation.id)}/>
								: <BsBox2Fill size={22} className="text-danger" onClick={() => deleteOrRestore(annotation.id)}/>
								}
							</Col >
							:<Col xs="auto" className="d-flex align-items-center"></Col >
						}

						<Col xs="auto" className="d-flex align-items-center">
							{annotation.is_visible 
							? <BsEyeFill size={22} onClick={() => toggleAnnotationVisibility(annotation.id)}/> 
							: <BsEyeSlashFill size={22} onClick={() => toggleAnnotationVisibility(annotation.id)}/>}
						</Col>
					</Row>
				</Container>
			</Card.Header>
			<Card.Body>
				<Card.Text style={{ fontSize: '0.8rem' }}>
					Annotator: {annotation.cropdex_user.display_name}<br/>
					Date Annotated: {formatDate(annotation.createdAt)}<br/>
					Actual Label: {labelRecognition.label}
				</Card.Text>
			</Card.Body>
		</Card>
	);
}

export default AnnotationComponent;