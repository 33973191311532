import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { BsArrowRightSquare, BsArrowLeftSquare } from "react-icons/bs";
import {cropList, retrieveCropName} from "../utilities/CropProvider";
import AnnotationComponent from "./AnnotationComponent";
import NewAnnotationComponent from "./NewAnnotationComponent";
import ImageCanvasComponent from "./ImageCanvasComponent";
import LabelSelectionListComponent from "./LabelSelectionListComponent"
import AnnotationListComponent from "./AnnotationListComponent"
import '../../App.css';

const ViewImageComponent = () => {

	let navigate = useNavigate();

	function changeLocation(placeToGo) {
		navigate(placeToGo, { replace: true });
		window.location.reload();
	}

	const { imageid } = useParams();
	const [userData, setUserData] = useState({});
	const [data, setData] = useState(null);
	const [annotations, setAnnotations] = useState([]);
	const [newAnnotations, setNewAnnotations] = useState([]);

	const [isEditing, setIsEditing] = useState(false);

	const [cropid, setCropid] = useState(-1);
	const [validity, setValidity] = useState(-1);

	const [pdTabState, setPDTabState] = useState(0);
	const [annotationTabState, setAnnotationTabState] = useState(0);

	const [selectedLabel, setSelectedLabel] = useState("unknown");

	const handleCropSelect = (eventKey) => {
		setCropid(cropList.indexOf(eventKey)+1);
	}

	const handleValiditySelect = (eventKey) => {
		if (eventKey == "valid") {
			setValidity(1);
		} else if (eventKey == "invalid") {
			setValidity(0);
		} else if (eventKey == "peer") {
			setValidity(-2);
		}
	}

	const handleSaveClick = async () => {
		try {
			const cookie = Cookies.get('cdexuser');

			const body = {
				id : data.id,
				photo_validity : validity,
				crop_id : cropid,
				edited_annotations : annotations,
				added_annotations : newAnnotations
			}

			const response = await fetch('https://www.cropdex.org/image-data/update-image-data', {
				method: 'POST',
				headers: {'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'},
				body: JSON.stringify(body),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const tdata = await response.json();
			changeLocation(`/edit-image/${data.id}`)
			//setResponseData(data);
		} catch (error) {
			console.error('There was an error:', error);
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const cookie = Cookies.get('cdexuser');
				const response = await fetch(`https://www.cropdex.org/image-data/get-image-information?id=${imageid}`, { method: 'GET', headers: { 'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'}});
				const jsonData = await response.json();
				setData(jsonData.image_data);
				setUserData(jsonData.authorized_user_info);

				if (jsonData.image_data.cropdex_annotations != null && jsonData.image_data.cropdex_annotations != undefined) {
					for (let i = 0; i < jsonData.image_data.cropdex_annotations.length; i++) {
						jsonData.image_data.cropdex_annotations[i]["is_visible"] = true;
					}
					setAnnotations(jsonData.image_data.cropdex_annotations);
				}
				setCropid(jsonData.image_data.crop_id);
				setValidity(jsonData.image_data.photo_validity);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	if (data){
		return (
			<Stack gap={2} className="pb-3">
				<div className="d-flex align-items-center justify-content-between  mt-3 me-3 ms-3">
					<Button variant="success" onClick={() => changeLocation(`/edit-image/${data.id-1}`)}>Prev <BsArrowLeftSquare /></Button>
					<Button variant="success" onClick={() => changeLocation(`/edit-image/${data.id+1}`)}>Next <BsArrowRightSquare /></Button>
				</div>
				<Card className="ms-3 me-3 mb-3">
					<Card.Body>
						<Card.Text>
							<Container>
								<Row>
									<Col>
										<h5>Uploaded By: {data.cropdex_user.display_name}</h5>
										<h6>Date Uploaded: {data.date_taken}</h6>
										<Stack direction="horizontal" gap={3}>
											{isEditing ?
												(
													<>
														<DropdownButton id="dropdown-basic-button" variant="success" title={retrieveCropName(cropid)} onSelect={handleCropSelect}>
															<Dropdown.ItemText>Change the associated crop</Dropdown.ItemText>
															{cropList.map((cropOption, i) => {
																return(<Dropdown.Item eventKey={cropOption}>{cropOption}</Dropdown.Item>)
															})}
														</DropdownButton>
														<DropdownButton 
															id="dropdown-basic-button" 
															variant="success" 
															onSelect={handleValiditySelect} 
															title={
																(validity == -2) ? "Requires Peer Evaluation"
																: (validity == 0) ? "Invalid Image"
																: (validity == 1) ? "Valid Image"
																: (validity == 2) ? "Ready For Training" 
																: "Not Validated Yet"}>
															<Dropdown.Item eventKey="peer">Apply Image for Peer Evaluation</Dropdown.Item>
															<Dropdown.Item eventKey="invalid">Flag as Invalid Image</Dropdown.Item>
															<Dropdown.Item eventKey="valid">Flag as Valid Image</Dropdown.Item>
														</DropdownButton>
													</>
												)
												:(
													<>
														<Button variant="outline-success">{retrieveCropName(cropid)}</Button>
														<Button variant="outline-success">{(validity == -1) ? "Not Validated Yet" : (validity == 0) ? "Invalid Image" : "Valid Image"}</Button>
													</>
												)
											}
										</Stack>
									</Col>
									<Col md="auto" className="d-flex align-items-center justify-content-center">
										{isEditing
											? <Button variant="primary" onClick={() => {setIsEditing(false); handleSaveClick();}}>Save Changes</Button>
											: <Button variant="primary" onClick={() => setIsEditing(true)}>Edit</Button>
										}
									</Col>
								</Row>
							</Container>
						</Card.Text>
					</Card.Body>
				</Card>
				<Container className="mr-3 ml-3 mb-3 mt-1" fluid>
					<Row>
						{isEditing 
						? (
							<Col>
								<LabelSelectionListComponent
									cropid={cropid}
									setPDTabState={setPDTabState}
									setSelectedLabel={setSelectedLabel}/>
							</Col>
						): null}
						<Col>
							<ImageCanvasComponent 
								imageId={imageid}
								isEditing={isEditing}
								selectedLabel={selectedLabel}
								pdTabState={pdTabState}
								annotationTabState={annotationTabState}
								annotations={annotations}
								newAnnotations={newAnnotations}
								setNewAnnotations={setNewAnnotations}/>
						</Col>
						<Col>
							<AnnotationListComponent
								userData={userData}
								isEditing={isEditing}
								annotations={annotations}
								newAnnotations={newAnnotations}
								setAnnotations={setAnnotations}
								setNewAnnotations={setNewAnnotations}
								setAnnotationTabState={setAnnotationTabState}
							/>
						</Col>
					</Row>
				</Container>
			</Stack>
		);
	} else {
		return (
			<Stack gap={2} className="align-items-center">
				<div className="p-2">Loading...</div>
			</Stack>
		);
	}
}

export default ViewImageComponent;