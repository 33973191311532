import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// function for chunking array to make row distribution easier
const arrayChunk = (arr, n) => {
	const array = arr.slice();
	const chunks = [];
	while (array.length) chunks.push(array.splice(0, n));
	return chunks;
};

const ImageDivisionListComponent = ({division}) => {
	
	const { crop_id, photo_validity } = useParams();
	let navigate = useNavigate();

	function changeLocation(placeToGo) {
		navigate(placeToGo, { replace: true });
		window.location.reload();
	}

	const [data, setData] = useState(null);
	
	const validityTable = {
		"-2":"Requires Peer Evaluation",
		"-1":"Not Validated Yet",
		"0":"Invalid Image",
		"1":"Valid Image",
		"2":"Ready For Training"
	}

	const labelTypeTable = {
		"unknown": "Images w/ Unclassified Annotations",
		"pest": "Images w/ Pests",
		"disease": "Images w/ Diseases"
	}

	function handleButtonClick (buttonData) {
		if (division == "association") {
			changeLocation(`/association-images/${buttonData.id}`)
		} else if (division == "crop") {
			changeLocation(`/crops/${buttonData.id}`)
		} else if (division == "cropvalidity") {
			changeLocation(`/crops/${crop_id}/${buttonData.division}`)
		} else if (division == "cropvaliditylabeltype") {
			changeLocation(`/crop-images/${crop_id}/${photo_validity}/${buttonData.division}`)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const cookie = Cookies.get('cdexuser');

				const data_query = 
					(division == "association") ? 'https://www.cropdex.org/associations/all-associations'
					: (division == "crop") ? 'https://www.cropdex.org/crops/all-crops'
					: (division == "cropvalidity") ? `https://www.cropdex.org/crops/crop-validities?crop_id=${crop_id}`
					: (division == "cropvaliditylabeltype") ? `https://www.cropdex.org/crops/crop-validity-label-types?crop_id=${crop_id}&photo_validity=${photo_validity}`
					: 'https://www.cropdex.org/associations/all-associations'

				const response = await fetch(data_query, { method: 'GET', headers: { 'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'}});
				const jsonData = await response.json();
				setData(jsonData);
			} catch (error) { 
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	if (data == null) {
		return (
			<div className="image-list-div">
				<Spinner animation="border" />
			</div>
		)
	} else {
		return (
			<Container className="m-3">
			{ arrayChunk(data, 3).map((row, i) => (
					<Row key={i}>
						{row.map((col, j) => (
							<Col key={j+i} xs={6} md={4} className="mb-3" style={{alignItems:'center'}}>
								<Button
									variant="outline-success"
									style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px' }} 
									className="custom-fill-cell" 
									onClick={() => {handleButtonClick(col)}}>
										<h5>
											{(division == "association") ? col.division
											:(division == "crop") ? col.division
											:(division == "cropvalidity") ? validityTable[`${col.division}`]
											:(division == "cropvaliditylabeltype") ? labelTypeTable[col.division]
											: "Loading..."}
										</h5>
										<h6>{col.total_images} Photos</h6>
								</Button>
							</Col>
						))}
					</Row>
				))
			}
			</Container>
		);
	}
}

export default ImageDivisionListComponent;