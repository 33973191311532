import React, { useEffect, useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Badge from 'react-bootstrap/Badge';
import retrieveDiseaseLabels from "../utilities/DiseaseLabelProvider";
import retrievePestLabels from "../utilities/PestLabelProvider";

const LabelSelectionListComponent = ({cropid, setPDTabState, setSelectedLabel}) => {

	const [pestLabels, setPestLabels] = useState([]);
	const [pestDisplayNames, setPestDisplayNames] = useState({});
	const [diseaseLabels, setDiseaseLabels] = useState([]);
	const [diseaseDisplayNames, setDiseaseDisplayNames] = useState({});

	const [selectedPestLabel, setSelectedPestLabel] = useState(-1);
	const [selectedDiseaseLabel, setSelectedDiseaseLabel] = useState(-1);

	const togglePestLabel = (index) => {
		setSelectedPestLabel(index)
		if (index == -1){
			setSelectedLabel("unknown")
		} else {
			setSelectedLabel(pestLabels[index])
		}
	}

	const toggleDiseaseLabel = (index) => {
		setSelectedDiseaseLabel(index)
		if (index == -1){
			setSelectedLabel("unknown")
		} else {
			setSelectedLabel(diseaseLabels[index])
		}
	}

	const handleTabSelect = (selectedTab) => {
		if (selectedTab == "pestTab") {
			setPDTabState(0);
			togglePestLabel(-1);
		} else if (selectedTab == "diseaseTab") {
			setPDTabState(1);
			toggleDiseaseLabel(-1);
		}
	};

	useEffect(() => {
		if (cropid > 0) {
			const pLabels = retrievePestLabels(cropid);
			const dLabels = retrieveDiseaseLabels(cropid);
			setPestLabels(pLabels.labels);
			setPestDisplayNames(pLabels.displayNames);
			setDiseaseLabels(dLabels.labels);
			setDiseaseDisplayNames(dLabels.displayNames);
		}
	}, [cropid])

	return (
		<Tabs defaultActiveKey="pestTab" id="pd-tab" className="mb-3" fill onSelect={handleTabSelect}>
			<Tab eventKey="pestTab" title="Pests">
				<ListGroup>
					<ListGroup.Item key={1} variant="dark">Select a Pest to annotate with</ListGroup.Item>
					{pestLabels.map((label, i) => {
						if (selectedPestLabel === i) {
							return(<ListGroup.Item key={i+2} variant="success" action onClick={() => togglePestLabel(-1)}>{pestDisplayNames[label]} <Badge bg="success">Selected</Badge></ListGroup.Item>)
						} else {
							return(<ListGroup.Item key={i+2} action onClick={() => togglePestLabel(i)}>{pestDisplayNames[label]}</ListGroup.Item>)
						}
					})}
				</ListGroup>
			</Tab>
			<Tab eventKey="diseaseTab" title="Diseases" onSelect={handleTabSelect}>
				<ListGroup>
					<ListGroup.Item key={1} variant="dark">Select a Disease to annotate with</ListGroup.Item>
					{diseaseLabels.map((label, i) => {
						if (selectedDiseaseLabel === i) {
							return(<ListGroup.Item key={i+2} variant="success" action onClick={() => toggleDiseaseLabel(-1)}>{diseaseDisplayNames[label]} <Badge bg="success">Selected</Badge></ListGroup.Item>)
						} else {
							return(<ListGroup.Item key={i+2} action onClick={() => toggleDiseaseLabel(i)}>{diseaseDisplayNames[label]}</ListGroup.Item>)
						}
					})}
				</ListGroup>
			</Tab>
		</Tabs>
	);
}

export default LabelSelectionListComponent;