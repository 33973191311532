import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Cookies from 'js-cookie';
import { Link, useNavigate  } from 'react-router-dom';


const LoginComponent = () => {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	let navigate = useNavigate();
	function changeLocation(placeToGo){
		navigate(placeToGo, { replace: true });
		window.location.reload();
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const credentials = {
			username: email,
			password: password
		}
		try {
			const response = await fetch('https://www.cropdex.org/cropdex_users/loginauth', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(credentials),
			});

			const data = await response.json();

			if (response.ok && data.token != undefined) {
				Cookies.set('cdexuser', data.token, { expires: 3 })
				changeLocation('/crops')
			} else {
				// Handle login error, e.g., show error message
				console.error('Login failed:', data);
			}
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
			<Card style={{ width: '25rem' }}>
				<Card.Body>
					<Card.Title>Login to CropDex</Card.Title>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Email address</Form.Label>
							<Form.Control 
								type="email"
								placeholder="Enter email"
								value={email} 
								onChange={(e) => setEmail(e.target.value)} 
							/>
							<Form.Text className="text-muted">
								We'll never share your email with anyone else.
							</Form.Text>
						</Form.Group>

						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control 
								type="password" 
								placeholder="Password"
								value={password} 
								onChange={(e) => setPassword(e.target.value)}
								 />
						</Form.Group>
						<Button variant="primary" type="submit">Submit</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
}

export default LoginComponent;