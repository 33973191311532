const MangoDiseases = {
	MangoAnthracnose : "Anthracnose",
	MangoStemEndRot : "Stem End Rot",
	MangoPowderyMildew : "Powdery Mildew",
	MangoSootyMold : "Sooty Mold",
	MangoBacterialCanker : "Bacterial Canker",
	MangoScab : "Scab",
}

const AbacaDiseases = {
	AbacaCordanaLeafSpot : "Cordana Leaf Spot",
	AbacaLeafBlotch : "Leaf Blotch",
	AbacaBunchyTop : "Abaca Bunchy Top",
	AbacaMosaic : "Abaca Mosaic",
	AbacaNematodeDisease : "Nematode Disease",
}

const RubberDiseases = {
	RubberLeafSpot : "Leaf Spot",
	RubberAnthracnoseLeafBlight : "Anthracnose Leaf Blight",
	RubberPowderyMildew : "Powdery Mildew",
	RubberTipBlight : "Tip Blight",
	RubberLeafBlight : "Leaf Blight",
	RubberPinkDisease : "Pink Disease",
	RubberStemBleeding : "Stem Bleeding",
	RubberKnobGall : "Knob Gall",
	RubberBlackStripeAndStemCracking : "Black Stripe and Stem Cracking",
}	

const PapayaDiseases = {
	PapayaBlackSpot : "Black Spot",
	PapayaCorynesporaLeafSpot : "Corynespora Leaf Spot",
	PapayaPowderyMildew : "Powdery Mildew",
	PapayaDampingOff : "Damping-Off",
	PapayaPhytophthoraBlight : "Phytophthora Blight",
	PapayaAnthracnose : "Anthracnose",
	PapayaCercosporaBlackSpot : "Cercospora Black Spot",
	PapayaBacterialCrownRot : "Bacterial Crown Rot",
	PapayaRingspot : "Papaya Ringspot",
	PapayaRootKnotNematode : "Root Knot Nematode",
}

const AmpalayaDiseases = {
	AmpalayaDampingOff : "Damping-Off",
	AmpalayaSouthernBlight : "Southern Blight",
	AmpalayaCercosporaLeafSpot : "Cercospora Leaf Spot",
	AmpalayaDownyMildew : "Downy Mildew",
	AmpalayaFusariumWilt : "Fusarium Wilt",
	AmpalayaGummyStemBlight : "Gummy Stem Blight",
	AmpalayaPowderyMildew : "Powdery Mildew",
	AmpalayaBacterialWilt : "Bacterial Wilt",
	AmpalayaLittleLeaf : "Little Leaf",
	AmpalayaNamamarako : "Namamarako",
	AmpalayaZucchiniYellowMosaic : "Zucchini Yellow Mosaic",
}

const CitrusDiseases = {
	CitrusGreening : "Citrus Greening",
	CitrusTristeza : "Citrus Tristeza",
	CitrusGummosis : "Citrus Gummosis",
	CitrusCanker : "Citrus Canker",
	CitrusScab : "Citrus Scab",
}
	
const EggplantDiseases = {
	EggplantPhomopsisBlight : "Phomopsis Blight",
	EggplantPhytophthoraBlight : "Phytophthora Blight",
	EggplantCercosporaLeafSpot : "Cercospora Leaf Spot",
	EggplantAnthracnose : "Anthracnose",
	EggplantBacterialWilt : "Bacterial Wilt",
	EggplantPowderMildew : "Powder Mildew",
	EggplantSclerotiumStemRot : "Sclerotium Stem Rot",	
}

const CabbageDiseases = {
	CabbageClubRoot : "Club Root",
	CabbageAlternariaLeafSpot : "Alternaria Leaf Spot",
	CabbageDownyMildew : "Downy Mildew",
	CabbageHeadRot : "Head Rot",
	CabbageBlackRot : "Black Rot",
	CabbageAnthracnose : "Anthracnose",
	CabbageBacterialSoftRot : "Bacterial Soft Rot",
	CabbageChoanephoraWetRot : "Choanephora Wet Rot",
	CabbagePhomaStemCanker : "Phoma Stem Canker",
}

const CassavaDiseases = {
	CassavaWitchesBroom : "Cassava Witches Broom",
	CassavaPhytophthoraLeafBlight : "Phytophthora Leaf Blight",
}

const PineappleDiseases = {
	PineappleHeartRot : "Phytopthora Heart Rot",
	PineappleHeartAndRootRot : "Heart and Root Rot",
	PineappleAnthracnose : "Anthracnose",
	PineappleLeafBlotch : "Leaf Blotch",
	PineappleFusariumBlight : "Fusarium Blight",
	PineappleLeafSpot : "Leaf Spot",	
}

const RiceDiseases = {
	RiceBacterialLeafBlight : "Bacterial Leaf Blight",
	RiceBacterialLeafStreak : "Bacterial Leaf Streak",
	RiceBakanaeDisease : "Bakanae Disease",
	RiceBrownLeafSpot : "Brown Leaf Spot",
	RiceNarrowBrownSpot : "Narrow Brown Spot",
	RiceBlastDisease : "Rice Blast Disease",
	RiceFalseSmut : "Rice False Smut",
	RiceGrassyStuntVirus : "Rice Grassy Stunt Virus",
	RiceRaggedStuntVirus : "Rice Ragged Stunt Virus",
	RiceSheathBlight : "Rice Sheath Blight",
	RiceTungro : "Rice Tungro ",
	RiceBlastDisease : "Rice Blast Disease",
	RiceSheathRot : "Sheath Rot",
	RiceStemRot : "Stem Rot",
	RiceLeafScald : "Leaf Scald",	
}

const CornDiseases = {
	CornPhaeosphaeriaLeafSpot : "Phaeosphaeria Leaf Spot",
	CornLeafBlight : "Leaf Blight",
	CornSheathRot : "Sheath Rot",
	CornMaizeStreakDisease : "Maize Streak Disease",
	CornAspergillusEarRot : "Aspergillus Ear Rot",
	CornBacterialStalkRot : "Bacterial Stalk Rot",
	CornBandedLeafAndSheathBlight : "Banded Leaf and Sheath Blight",
	CornBrownSpotDisease : "Brown Spot Disease",
	CornCharcoalStalkRot : "Charcoal Stalk Rot",
	CornDampingOff : "Damping-Off",
	CornDiplodiaEarAndStalkRot : "Diplodia Ear and Stalk Rot",
	CornDiplodiaLeafBlight : "Diplodia Leaf Blight",
	CornDownyMildew : "Downy Mildew",
	CornFusariumEarRot : "Fusarium Ear Rot",
	CornGiberellaEarAndStalkRot : "Giberella Ear and Stalk Rot",
	CornGrayLeafSpot : "Gray Leaf Spot",
	CornMaizeDwarfMosaicVirus : "Maize Dwarf Mosaic Virus",
	CornNorthernLeafBlight : "Northern Corn Leaf Blight",
	CornSouthernLeafBlight : "Southern Corn Leaf Blight",
	CornPythiumStalkRot : "Pythium Stalk Rot",
	CornSeedRot : "Seed Rot",
	CornRust : "Corn Rust",
	CornEyeSpot : "Corn Eye Spot",
	CornSmut : "Corn Smut",
}

const CoconutDiseases = {
	CoconutBipolarisLeafSpot : "Bipolaris Leaf Spot",
	CoconutCadangCadangDisease : "Cadang-Cadang Disease",
	CoconutGrayLeafSpot : "Gray Leaf Spot",
	CoconutBudRot : "Coconut Bud Rot",	
}

const CoffeeDiseases = {
	CoffeeBerryBlotchDisease : "Berry Blotch Disease",
	CoffeeBrownEyeSpot : "Brown Eye Spot",
	CoffeeBerryDisease : "Coffee Berry Disease",
	CoffeeLeafRust : "Coffee Leaf Rust",	
}

const CacaoDiseases = {
	CacaoBlackCanker : "Black Canker",
	CacaoPodRot : "Cacao Pod Rot",
	CacaoFrostyPodRot : "Frosty Pod Rot",
	CacaoVascularStreakDieback : "Vascular Streak Dieback",	
}

const BananaDiseases = {
	BananaAnthracnose : "Anthracnose",
	BananaBractMosaic : "Banana Bract Mosaic",
	BananaBunchyTop : "Banana Bunchy Top",
	BananaCrossLeafSpot : "Black Cross Leaf Spot",
	BananaBlackLeafStreakDisease : "Black Leaf Streak Disease",
	BananaBlackSigatoka : "Black Sigatoka",
	BananaCordanaLeafSpot : "Cordana Leaf Spot",
	BananaCrownRot : "Crown Rot",
	BananaFreckleDisease : "Freckle Disease",
	BananaMokoDisease : "Moko Disease",
	BananaPanamaDisease : "Panama Disease",
	BananaStemEndRot : "Stem End Rot",
	BananaYellowSigatoka : "Yellow Sigatoka",
	BananaFusariumWilt : "Fusarium Wilt",	
}

const SoybeanDiseases = {
	SoybeanBacterialBlight : "Bacterial Blight",
	SoybeanBacterialPustule : "Bacterial Pustule",
	SoybeanDampingOff : "Damping-Off",
	SoybeanPurpleSeedStain : "Purple Seed Stain",
	SoybeanMosaic : "Soybean Mosaic",
	SoybeanRust : "Soybean Rust",	
}

const SugarcaneDiseases = {
	SugarcaneDownyMildew : "Downy Mildew",
	SugarcaneLeafScald : "Leaf Scald",
	SugarcaneLeafScorch : "Leaf Scorch",
	SugarcanePineappleDisease : "Pineapple Disease",
	SugarcanePokkahBoeng : "Pokkah Boeng",
	SugarcaneRatoonStuntingDisease : "Ratoon Stunting Disease",
	SugarcaneMosaic : "Sugarcane Mosaic",
	SugarcaneRust : "Sugarcane Rust",
	SugarcaneSmut : "Sugarcane Smut",
	SugarcaneYellowSpot : "Yellow Spot",
}


const TomatoDiseases = {
	TomatoAnthracnose : "Anthracnose",
	TomatoBacterialSpot : "Bacterial Spot",
	TomatoBacterialWilt : "Bacterial Wilt",
	TomatoCucumberMosaic : "Cucumber Mosaic",
	TomatoEarlyBlight : "Early Blight",
	TomatoLeafMold : "Tomato Leaf Mold",
	TomatoPotatoVirusY : "Potato Virus Y",
	TomatoPowderyMildew : "Powdery Mildew",
	TomatoLateBlight : "Late Blight",
	TomatoLeafCurl : "Tomato Leaf Curl",
	TomatoMosaic : "Tomato Mosaic",
}


const OnionDiseases = {
	OnionAnthracnoseTwister : "Anthracnose-Twister",
	OnionBasalRot : "Basal Rot",
	OnionPinkRoot : "Pink Root",
	OnionPurpleBlotch : "Purple Blotch",
	OnionDampingOff : "Damping-Off",
	OnionWhiteRot : "White Rot",
	OnionBacterialSoftRot : "Bacterial Soft Rot",
	OnionYellowDwarf : "Onion Yellow Dwarf",
}

const DiseaseCollection = [
	MangoDiseases,
	AbacaDiseases,
	RubberDiseases,
	PapayaDiseases,
	AmpalayaDiseases,
	CitrusDiseases,
	EggplantDiseases,
	CabbageDiseases,
	CassavaDiseases,
	PineappleDiseases,
	RiceDiseases,
	CornDiseases,
	CoconutDiseases,
	CoffeeDiseases,
	CacaoDiseases,
	BananaDiseases,
	SoybeanDiseases,
	SugarcaneDiseases,
	TomatoDiseases,
	OnionDiseases,
]

const retrieveDiseaseLabels = function(crop_id) {
	const diseases = DiseaseCollection[crop_id-1];
	return {
		labels: Object.keys(diseases),
		displayNames: diseases,
	}
}

export default retrieveDiseaseLabels;