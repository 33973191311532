import React, { useRef, useEffect, useState } from "react";
import Pagination from 'react-bootstrap/Pagination';

const ImagePaginationComponent = ({currentPage, numberOfPages, setCurrentPage}) => {
	
	const [paginationArray, setPaginationArray] = useState([]);

	useEffect(() => {
		const maxVisiblePages = 9;

		var firstVisiblePage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
		var lastVisiblePage = firstVisiblePage + maxVisiblePages - 1;

		if (lastVisiblePage > numberOfPages) {
			lastVisiblePage = numberOfPages;
			firstVisiblePage = Math.max(lastVisiblePage - maxVisiblePages + 1, 1);
		}

		let items = [];
		items.push(
			<Pagination.First onClick={() => {setCurrentPage(1)}}/>
		)
		items.push(
			<Pagination.Prev onClick={() => {setCurrentPage(Math.max(1,currentPage-1))}}/>
		)
		for (let number = firstVisiblePage; number <= lastVisiblePage; number++) {
			items.push(
				<Pagination.Item key={number} active={number === currentPage} onClick={() => {setCurrentPage(number)}}>
					{number}
				</Pagination.Item>,
			);
		}
		items.push(
			<Pagination.Next onClick={() => {setCurrentPage(Math.min(numberOfPages,currentPage + 1))}}/>
		)
		items.push(
			<Pagination.Last onClick={() => {setCurrentPage(numberOfPages)}}/>
		)
		setPaginationArray(items);
	}, [currentPage, numberOfPages]);

	return(
		<Pagination>{paginationArray}</Pagination>
	)
}

export default ImagePaginationComponent;