import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Container, Nav, Navbar, NavDropdown, Dropdown, DropdownButton } from 'react-bootstrap';
import { BsPersonFill } from "react-icons/bs";

const CropdexNavbar = () => {
	let navigate = useNavigate();

	function changeLocation(placeToGo){
		navigate(placeToGo, { replace: true });
		window.location.reload();
	}

	function logout() {
		Cookies.remove('cdexuser');
		changeLocation('/');
	}

	const [cookieChecked, setCookieChecked] = useState(false);
	const [userData, setUserData] = useState(null);
	const [show, setShow] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const cookie = Cookies.get('cdexuser');
				const user = await fetch('https://www.cropdex.org/users/get-user-details', { method: 'POST', headers: { 'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'}});
				const userJson = await user.json();
				setUserData(userJson);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
			setCookieChecked(true)
		};
		fetchData();
	}, []);

	return (
		<Navbar expand="lg" className="bg-body-tertiary">
			<Container>
				<Navbar.Brand as={Link} to="/">Cropdex</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="container-fluid">
						<Nav.Link as={Link} to="/" onClick={() => changeLocation('/')}>Home</Nav.Link>
						{cookieChecked && userData !== null && userData.access_level_id >= 5 
							? (
							<NavDropdown title="Image Data Set" show={show} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
								<NavDropdown.Item as={Link} to="/associations" onClick={() => changeLocation('/associations')}>Contributors</NavDropdown.Item>
								<NavDropdown.Item as={Link} to="/crops" onClick={() => changeLocation('/crops')}>Crops</NavDropdown.Item>
							</NavDropdown>
							)
							: null
						}
						{cookieChecked && userData !== null && userData.access_level_id === 6
							? <Nav.Link as={Link} to="/data-management" onClick={() => changeLocation('/data-management')}>Data Management</Nav.Link>
							: null
						}
						{cookieChecked && userData !== null && userData.access_level_id >= 5
							? <Nav.Link as={Link} to="/statistics" onClick={() => changeLocation('/statistics')}>Statistics</Nav.Link>
							: null

						}
						{cookieChecked && userData === null
							? <Nav.Link as={Link} to="/login" onClick={() => changeLocation('/login')}className="ms-auto">Login</Nav.Link>
							: null
						}
						{cookieChecked && userData !== null
							? 
							<div className="ms-auto d-flex align-items-center">
								<BsPersonFill className="me-2"/>
								<span className="me-2">Welcome, {userData.display_name}</span>
								<DropdownButton id="profile-dropdown" variant="" drop="down-centered">
									<Dropdown.Item >My Account</Dropdown.Item>
									<Dropdown.Item >My Annotations</Dropdown.Item>
									<Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
								</DropdownButton>
							</div>
							: null
						}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default CropdexNavbar;