import React, { useRef, useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { HeatmapLayerFactory } from '@vgrid/react-leaflet-heatmap-layer';

const HeatmapLayer = HeatmapLayerFactory();

const HeatMapComponent = ({dataPoints}) => {

	const mapRef = useRef(null);

	// Define bounds for the Philippines
	const southWest = [4.64, 116.91];
	const northEast = [21.12, 127.34];
	const bounds = [southWest, northEast];

	return (
        <MapContainer 
        	center={[12.8797, 121.7740]} 
        	zoom={5}
			style={{ height: "100vh", width: "100vw"}}
			ref={mapRef}
			maxBounds={bounds} // Restrict map view to the Philippines bounds
			maxBoundsViscosity={1.0} // Elastic dragging within bounds
        >
          <HeatmapLayer

            points= { dataPoints }
            longitudeExtractor={m => {
					var item_latitude = 0.0;
					var item_longitude = 0.0;
					if (m.cropdex_image_datum.coordinates){
						let substring = m.cropdex_image_datum.coordinates.replace('(', '');
						substring = substring.replace(')', '');
						let split_string = substring.split(',');
						item_latitude = parseFloat(split_string[0]);
						item_longitude = parseFloat(split_string[1]);
						if (isNaN(item_latitude) || isNaN(item_longitude)) {
							item_latitude = 0.0;
							item_longitude = 0.0;
						}
					}
            		return item_longitude
            	}
            }
            latitudeExtractor={m => {
					var item_latitude = 0.0;
					var item_longitude = 0.0;
					if (m.cropdex_image_datum.coordinates){
						let substring = m.cropdex_image_datum.coordinates.replace('(', '');
						substring = substring.replace(')', '');
						let split_string = substring.split(',');
						item_latitude = parseFloat(split_string[0]);
						item_longitude = parseFloat(split_string[1]);
						if (isNaN(item_latitude) || isNaN(item_longitude)) {
							item_latitude = 0.0;
							item_longitude = 0.0;
						}
					}
            		return item_latitude
            	}
            }
            intensityExtractor={m => 1} />
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
        </MapContainer>
	);

}

export default HeatMapComponent;