const MangoPests = {
	MangoCecidFly : "Cecid Fly",
	MangoBarkBorer : "Mango Bark Borer",
	MangoFruitFly : "Fruit Fly",
	MangoTwigBorer : "Twig Borers",
}

const AbacaPests = {
	AbacaAphid : "Abaca/Banana Aphid",
	AbacaRootCormWeevil : "Root or Corm Weevil",
	AbacaSlugCaterpillar : "Slug Caterpillar",
	AbacaWeevil : "Banana Weevils",
}

const RubberPests = {
	RubberRootBorer : "Rubber Root Borer",
	RubberSpiderMites : "Spider Mites",
	RubberScaleInsects : "Scale Insects",
	RubberMealyBugs : "Mealybugs",
	RubberThrips : "Thrips",
}

const PapayaPests = {
	PapayaRedSpiderMites : "Red Spider Mites",
	PapayaMealybug : "Papaya Mealybug",
	PapayaFruitFly : "Fruit Fly",
	PapayaPhilippineFruitFly : "Philippine Fruit Fly",
}

const AmpalayaPests = {
	AmpalayaFruitFly : "Fruit Fly",
	AmpalayaLeafFolder : "Leaffolder",
	AmpalayaAphids : "Aphids",
	AmpalayaGreenPeachAphid : "Green Peach Aphid",
	AmpalayaSemilooper : "Semilooper",
	AmpalayaLeafFootedPlantbug : "Leaf-footed plantbug",
	AmpalayaWhiteFly : "Whitefy",
}

const CitrusPests = {
	CitrusBarkBorer : "Citrus Bark Borer",
	CitrusRindBorer : "Citrus Rind Borer",
	CitrusCarpenterMoth : "Coffee Carpenter Moth",
	CitrusAphids : "Aphids",
}

const EggplantPests = {
	EggplantFruitShootBorer : "Eggplant Fruit and Shoot Borer",
	EggplantWhiteFly : "Whitefly",
	EggplantLeafMiner : "Leafminer",
	EggplantRedSpiderMites : "Red Spider Mites",
	EggplantThrips : "Thrips",
}

const CabbagePests = {
	CabbageLeafMiner : "Leafminer",
	CabbageCutworm : "Cutworm",
	CabbageDiamondBackMoth : "Diamond-back Moth",
	CabbageWhiteFly : "Whitefly",
	CabbageGreenSoldierBug : "Green Soldier Bug",
	CabbageWorm : "Cabbage Worm",
}

const CassavaPests = {
	CassavaRedSpiderMites : "Red Spider Mites",
	CassavaSoftScale : "Soft Scale",
	CassavaStripedMealybug : "Striped Mealybug",
	CassavaPapayaMealybug : "Papaya Mealybug",
	CassavaWhiteFly : "Whitefly",
}

const PineapplePests = {
	PineappleJuneBeetle : "June Beetle",
	PineappleMealybug : "Pineapple Mealybug",
	PineappleSouringBeetle : "Souring Beetle",
	PineappleFruitFly : "Fruit Fly",
}

const RicePests = {
	RiceBrownPlanthopper : "Brown Planthopper",
	RiceCutworm : "Cutworm",
	RiceGreenLeafhopper : "Green Leafhopper",
	RiceStemBorer : "Pink/Striped/White/Yellow Stem Borer",
	RiceBlackBug : "Rice Black Bug",
	RiceBug : "Rice Bug",
	RiceCaseworm : "Rice Caseworm",
	RiceEarCuttingCaterpillar : "Rice Ear-cutting Caterpillar",
	RiceGrainBug : "Rice Grain Bug",
	RiceLeafFolder : "Rice Leaf Folder",
	RiceWhorlMaggot : "Whorl Maggot",
}

const CornPests = {
	CornAphid : "Aphid",
	CornArmyworm : "Armyworm",
	CornCommonCutworm : "Common Cutworm",
	CornBorer : "Corn Borer",
	CornEarworm : "Corn Earworm",
	CornPlanthopper : "Corn Planthopper",
	CornSeedlingMaggot : "Corn Seedling Maggot",
	CornSemilooper : "Corn Semilooper",
	CornFallArmyworm : "Fall Armyworm",
	CornGrassArmyworm : "Grass Armyworm",
	CornOrientalMigratoryLocust : "Oriental Migratory Locust",
	CornTrueArmyworm : "True Armyworm",
	CornWhiteGrub : "White Grub",
}

const CoconutPests = {
	CoconutAsiaticPalmWeevil : "Asiatic Palm Weevil",
	CoconutBuffMealybug : "Coconut Buff Mealybug",
	CoconutLeafBeetle : "Coconut Leaf Beetle",
	CoconutLeafMiner : "Coconut Leaf Miner",
	CoconutSpikeMoth : "Coconut Spike Moth",
	CoconutRhinocerosBeetle : "Rhinoceros Beetle",
	CoconutSlugCaterpillar : "Slug Caterpillar",
}

const CoffeePests = {
	CoffeeBlackCitrusAphid : "Black Citrus Aphid",
	CoffeeBlackScaleInsect : "Black Scale Insect",
	CoffeeBerryBorer : "Coffee Berry Borer",
	CoffeeFlatidLeafhopper : "Flatid Leafhopper",
	CoffeeGreenScaleInsect : "Green Coffee Scale Insect",
	CoffeeLeafFolder : "Leaf Folder",
	CoffeeLeafhopper : "Coffee Leafhopper",
	CoffeeMealybug : "Mealybug",
	CoffeePulvinariaScaleInsect : "Pulvinaria Scale Insect",
	CoffeeRedStemBorer : "Red Stem Borer",
	CoffeeRedBandedThrips : "Red-banded Thrips",
	CoffeeRicaniidHopper : "Ricaniid Hopper",
	CoffeeStripedMealybug : "Striped Mealybug",
	CoffeeTussockCaterpillar : "Tussock Caterpillar",
	CoffeeWhiteStemBorer : "White Stem Borer",
}

const CacaoPests = {
	CacaoAphid : "Aphid",
	CacaoBarkBorer : "Cacao Bark Borer",
	CacaoPodBorer : "Cacao Pod Borer",
	CacaoTussockMoth : "Cacao Tussock Moth",
	CacaoLeafhopper : "Cacao Leafhopper",
	CacaoMealybug : "Mealybug",
	CacaoMiridBug : "Mirid Bug",
	CacaoRedBandedThrips : "Red Banded Thrips",
}

const BananaPests = {
	BananaAphid : "Banana Aphid",
	BananaFlowerThrips : "Banana Flower Thrips",
	BananaWhitefly : "Whitefly",
	BananaLeafroller : "Banana Leafroller",
	BananaCoconutScaleInsect : "Coconut Scale Insect",
	BananaCommonCutworm : "Common Cutworm",
	BananaFruitScarringBeetle : "Fruit Scarring beetle",
	BananaLaceBug : "Lace Bug",
	BananaPineappleMealybug : "Pineapple Mealybug",
	BananaPseudostemBorer : "Pseudostem Borer",
	BananaRhizomeBorer : "Rhizome Borer",
}

const SoybeanPests = {
	SoybeanBeanFly : "Bean Fly",
	SoybeanBlackCutworm : "Black Cutworm",
	SoybeanLeaffolder : "Leaffolder",
	SoybeanPodBorer : "Pod Borer",
	SoybeanAphid : "Soybean Aphid",
}

const SugarcanePests = {
	SugarcaneArmyworm : "Armyworm",
	SugarcaneCushionScale : "Cushion Scale",
	SugarcaneMigratoryLocust : "Migratory Locust",
	SugarcanePinkMealybug : "Pink Mealybug",
	SugarcaneRootGrub : "Root Grub",
	SugarcaneAphid : "Sugarcane Aphid",
	SugarcaneBorer : "Sugarcane Borer",
	SugarcaneGrayBorer : "Sugarcane Gray Borer",
	SugarcanePinkStemBorer : "Sugarcane Pink Stem Borer",
	SugarcaneTermite : "Termite",
	SugarcaneWireworm : "Wireworm",
	SugarcaneWoolyAphid : "Wooly Aphid",
}


const TomatoPests = {
	Tomato28SpottedLadyBeetle : "28-Spotted Lady Beetle",
	TomatoFruitworm : "Fruitworm",
	TomatoLeafminer : "Leafminer",
	TomatoMelonFruitFly : "Melon Fruit Fly",
	TomatoSemilooper : "Semilooper",
	TomatoWhitefly : "Whitefly",
	TomatoRedSpiderMite : "Red Spider Mite",
}


const OnionPests = {
	OnionArmyworm : "Onion Armyworm",
	OnionCutworm : "Cutworm",
	OnionLeafminer : "Leafminer",
	OnionThrips : "Thrips",
}

const PestCollection = [
	MangoPests,
	AbacaPests,
	RubberPests,
	PapayaPests,
	AmpalayaPests,
	CitrusPests,
	EggplantPests,
	CabbagePests,
	CassavaPests,
	PineapplePests,
	RicePests,
	CornPests,
	CoconutPests,
	CoffeePests,
	CacaoPests,
	BananaPests,
	SoybeanPests,
	SugarcanePests,
	TomatoPests,
	OnionPests,
]

const retrievePestLabels = function(crop_id) {
	const pests = PestCollection[crop_id-1];
	return {
		labels: Object.keys(pests),
		displayNames: pests,
	}
}

export default retrievePestLabels;