const recognizedDiseases = {
	MangoAnthracnose : "Anthracnose",
	MangoStemEndRot : "Stem End Rot",
	MangoPowderyMildew : "Powdery Mildew",
	MangoSootyMold : "Sooty Mold",
	MangoBacterialCanker : "Bacterial Canker",
	MangoScab : "Scab",
	AbacaCordanaLeafSpot : "Cordana Leaf Spot",
	AbacaLeafBlotch : "Leaf Blotch",
	AbacaBunchyTop : "Abaca Bunchy Top",
	AbacaMosaic : "Abaca Mosaic",
	AbacaNematodeDisease : "Nematode Disease",
	RubberLeafSpot : "Leaf Spot",
	RubberAnthracnoseLeafBlight : "Anthracnose Leaf Blight",
	RubberPowderyMildew : "Powdery Mildew",
	RubberTipBlight : "Tip Blight",
	RubberLeafBlight : "Leaf Blight",
	RubberPinkDisease : "Pink Disease",
	RubberStemBleeding : "Stem Bleeding",
	RubberKnobGall : "Knob Gall",
	RubberBlackStripeAndStemCracking : "Black Stripe and Stem Cracking",
	PapayaBlackSpot : "Black Spot",
	PapayaCorynesporaLeafSpot : "Corynespora Leaf Spot",
	PapayaPowderyMildew : "Powdery Mildew",
	PapayaDampingOff : "Damping-Off",
	PapayaPhytophthoraBlight : "Phytophthora Blight",
	PapayaAnthracnose : "Anthracnose",
	PapayaCercosporaBlackSpot : "Cercospora Black Spot",
	PapayaBacterialCrownRot : "Bacterial Crown Rot",
	PapayaRingspot : "Papaya Ringspot",
	PapayaRootKnotNematode : "Root Knot Nematode",
	AmpalayaDampingOff : "Damping-Off",
	AmpalayaSouthernBlight : "Southern Blight",
	AmpalayaCercosporaLeafSpot : "Cercospora Leaf Spot",
	AmpalayaDownyMildew : "Downy Mildew",
	AmpalayaFusariumWilt : "Fusarium Wilt",
	AmpalayaGummyStemBlight : "Gummy Stem Blight",
	AmpalayaPowderyMildew : "Powdery Mildew",
	AmpalayaBacterialWilt : "Bacterial Wilt",
	AmpalayaLittleLeaf : "Little Leaf",
	AmpalayaNamamarako : "Namamarako",
	AmpalayaZucchiniYellowMosaic : "Zucchini Yellow Mosaic",
	CitrusGreening : "Citrus Greening",
	CitrusTristeza : "Citrus Tristeza",
	CitrusGummosis : "Citrus Gummosis",
	CitrusCanker : "Citrus Canker",
	CitrusScab : "Citrus Scab",
	EggplantPhomopsisBlight : "Phomopsis Blight",
	EggplantPhytophthoraBlight : "Phytophthora Blight",
	EggplantCercosporaLeafSpot : "Cercospora Leaf Spot",
	EggplantAnthracnose : "Anthracnose",
	EggplantBacterialWilt : "Bacterial Wilt",
	EggplantPowderMildew : "Powder Mildew",
	EggplantSclerotiumStemRot : "Sclerotium Stem Rot",
	CabbageClubRoot : "Club Root",
	CabbageAlternariaLeafSpot : "Alternaria Leaf Spot",
	CabbageDownyMildew : "Downy Mildew",
	CabbageHeadRot : "Head Rot",
	CabbageBlackRot : "Black Rot",
	CabbageAnthracnose : "Anthracnose",
	CabbageBacterialSoftRot : "Bacterial Soft Rot",
	CabbageChoanephoraWetRot : "Choanephora Wet Rot",
	CabbagePhomaStemCanker : "Phoma Stem Canker",
	CassavaWitchesBroom : "Cassava Witches Broom",
	CassavaPhytophthoraLeafBlight : "Phytophthora Leaf Blight",
	PineappleHeartRot : "Phytopthora Heart Rot",
	PineappleHeartAndRootRot : "Heart and Root Rot",
	PineappleAnthracnose : "Anthracnose",
	PineappleLeafBlotch : "Leaf Blotch",
	PineappleFusariumBlight : "Fusarium Blight",
	PineappleLeafSpot : "Leaf Spot",
	RiceBacterialLeafBlight : "Bacterial Leaf Blight",
	RiceBacterialLeafStreak : "Bacterial Leaf Streak",
	RiceBakanaeDisease : "Bakanae Disease",
	RiceBrownLeafSpot : "Brown Leaf Spot",
	RiceNarrowBrownSpot : "Narrow Brown Spot",
	RiceBlastDisease : "Rice Blast Disease",
	RiceFalseSmut : "Rice False Smut",
	RiceGrassyStuntVirus : "Rice Grassy Stunt Virus",
	RiceRaggedStuntVirus : "Rice Ragged Stunt Virus",
	RiceSheathBlight : "Rice Sheath Blight",
	RiceTungro : "Rice Tungro ",
	RiceBlastDisease : "Rice Blast Disease",
	RiceSheathRot : "Sheath Rot",
	RiceStemRot : "Stem Rot",
	RiceLeafScald : "Leaf Scald",
	CornPhaeosphaeriaLeafSpot : "Phaeosphaeria Leaf Spot",
	CornLeafBlight : "Leaf Blight",
	CornSheathRot : "Sheath Rot",
	CornMaizeStreakDisease : "Maize Streak Disease",
	CornAspergillusEarRot : "Aspergillus Ear Rot",
	CornBacterialStalkRot : "Bacterial Stalk Rot",
	CornBandedLeafAndSheathBlight : "Banded Leaf and Sheath Blight",
	CornBrownSpotDisease : "Brown Spot Disease",
	CornCharcoalStalkRot : "Charcoal Stalk Rot",
	CornDampingOff : "Damping-Off",
	CornDiplodiaEarAndStalkRot : "Diplodia Ear and Stalk Rot",
	CornDiplodiaLeafBlight : "Diplodia Leaf Blight",
	CornDownyMildew : "Downy Mildew",
	CornFusariumEarRot : "Fusarium Ear Rot",
	CornGiberellaEarAndStalkRot : "Giberella Ear and Stalk Rot",
	CornGrayLeafSpot : "Gray Leaf Spot",
	CornMaizeDwarfMosaicVirus : "Maize Dwarf Mosaic Virus",
	CornNorthernLeafBlight : "Northern Corn Leaf Blight",
	CornSouthernLeafBlight : "Southern Corn Leaf Blight",
	CornPythiumStalkRot : "Pythium Stalk Rot",
	CornSeedRot : "Seed Rot",
	CornRust : "Corn Rust",
	CornEyeSpot : "Corn Eye Spot",
	CornSmut : "Corn Smut",
	CoconutBipolarisLeafSpot : "Bipolaris Leaf Spot",
	CoconutCadangCadangDisease : "Cadang-Cadang Disease",
	CoconutGrayLeafSpot : "Gray Leaf Spot",
	CoconutBudRot : "Coconut Bud Rot",
	CoffeeBerryBlotchDisease : "Berry Blotch Disease",
	CoffeeBrownEyeSpot : "Brown Eye Spot",
	CoffeeBerryDisease : "Coffee Berry Disease",
	CoffeeLeafRust : "Coffee Leaf Rust",
	CacaoBlackCanker : "Black Canker",
	CacaoPodRot : "Cacao Pod Rot",
	CacaoFrostyPodRot : "Frosty Pod Rot",
	CacaoVascularStreakDieback : "Vascular Streak Dieback",
	BananaAnthracnose : "Anthracnose",
	BananaBractMosaic : "Banana Bract Mosaic",
	BananaBunchyTop : "Banana Bunchy Top",
	BananaCrossLeafSpot : "Black Cross Leaf Spot",
	BananaBlackLeafStreakDisease : "Black Leaf Streak Disease",
	BananaBlackSigatoka : "Black Sigatoka",
	BananaCordanaLeafSpot : "Cordana Leaf Spot",
	BananaCrownRot : "Crown Rot",
	BananaFreckleDisease : "Freckle Disease",
	BananaMokoDisease : "Moko Disease",
	BananaPanamaDisease : "Panama Disease",
	BananaStemEndRot : "Stem End Rot",
	BananaYellowSigatoka : "Yellow Sigatoka",
	BananaFusariumWilt : "Fusarium Wilt",
	SoybeanBacterialBlight : "Bacterial Blight",
	SoybeanBacterialPustule : "Bacterial Pustule",
	SoybeanDampingOff : "Damping-Off",
	SoybeanPurpleSeedStain : "Purple Seed Stain",
	SoybeanMosaic : "Soybean Mosaic",
	SoybeanRust : "Soybean Rust",
	SugarcaneDownyMildew : "Downy Mildew",
	SugarcaneLeafScald : "Leaf Scald",
	SugarcaneLeafScorch : "Leaf Scorch",
	SugarcanePineappleDisease : "Pineapple Disease",
	SugarcanePokkahBoeng : "Pokkah Boeng",
	SugarcaneRatoonStuntingDisease : "Ratoon Stunting Disease",
	SugarcaneMosaic : "Sugarcane Mosaic",
	SugarcaneRust : "Sugarcane Rust",
	SugarcaneSmut : "Sugarcane Smut",
	SugarcaneYellowSpot : "Yellow Spot",
	TomatoAnthracnose : "Anthracnose",
	TomatoBacterialSpot : "Bacterial Spot",
	TomatoBacterialWilt : "Bacterial Wilt",
	TomatoCucumberMosaic : "Cucumber Mosaic",
	TomatoEarlyBlight : "Early Blight",
	TomatoLeafMold : "Tomato Leaf Mold",
	TomatoPotatoVirusY : "Potato Virus Y",
	TomatoPowderyMildew : "Powdery Mildew",
	TomatoLateBlight : "Late Blight",
	TomatoLeafCurl : "Tomato Leaf Curl",
	TomatoMosaic : "Tomato Mosaic",
	OnionAnthracnoseTwister : "Anthracnose-Twister",
	OnionBasalRot : "Basal Rot",
	OnionPinkRoot : "Pink Root",
	OnionPurpleBlotch : "Purple Blotch",
	OnionDampingOff : "Damping-Off",
	OnionWhiteRot : "White Rot",
	OnionBacterialSoftRot : "Bacterial Soft Rot",
	OnionYellowDwarf : "Onion Yellow Dwarf",
}

const recognizedPests = {
	MangoCecidFly : "Cecid Fly",
	MangoBarkBorer : "Mango Bark Borer",
	MangoFruitFly : "Fruit Fly",
	MangoTwigBorer : "Twig Borers",
	AbacaAphid : "Abaca/Banana Aphid",
	AbacaRootCormWeevil : "Root or Corm Weevil",
	AbacaSlugCaterpillar : "Slug Caterpillar",
	AbacaWeevil : "Banana Weevils",
	RubberRootBorer : "Rubber Root Borer",
	RubberSpiderMites : "Spider Mites",
	RubberScaleInsects : "Scale Insects",
	RubberMealyBugs : "Mealybugs",
	RubberThrips : "Thrips",
	PapayaRedSpiderMites : "Red Spider Mites",
	PapayaMealybug : "Papaya Mealybug",
	PapayaFruitFly : "Fruit Fly",
	PapayaPhilippineFruitFly : "Philippine Fruit Fly",
	AmpalayaFruitFly : "Fruit Fly",
	AmpalayaLeafFolder : "Leaffolder",
	AmpalayaAphids : "Aphids",
	AmpalayaGreenPeachAphid : "Green Peach Aphid",
	AmpalayaSemilooper : "Semilooper",
	AmpalayaLeafFootedPlantbug : "Leaf-footed plantbug",
	AmpalayaWhiteFly : "Whitefy",
	CitrusBarkBorer : "Citrus Bark Borer",
	CitrusRindBorer : "Citrus Rind Borer",
	CitrusCarpenterMoth : "Coffee Carpenter Moth",
	CitrusAphids : "Aphids",
	EggplantFruitShootBorer : "Eggplant Fruit and Shoot Borer",
	EggplantWhiteFly : "Whitefly",
	EggplantLeafMiner : "Leafminer",
	EggplantRedSpiderMites : "Red Spider Mites",
	EggplantThrips : "Thrips",
	CabbageLeafMiner : "Leafminer",
	CabbageCutworm : "Cutworm",
	CabbageDiamondBackMoth : "Diamond-back Moth",
	CabbageWhiteFly : "Whitefly",
	CabbageGreenSoldierBug : "Green Soldier Bug",
	CabbageWorm : "Cabbage Worm",
	CassavaRedSpiderMites : "Red Spider Mites",
	CassavaSoftScale : "Soft Scale",
	CassavaStripedMealybug : "Striped Mealybug",
	CassavaPapayaMealybug : "Papaya Mealybug",
	CassavaWhiteFly : "Whitefly",
	PineappleJuneBeetle : "June Beetle",
	PineappleMealybug : "Pineapple Mealybug",
	PineappleSouringBeetle : "Souring Beetle",
	PineappleFruitFly : "Fruit Fly",
	RiceBrownPlanthopper : "Brown Planthopper",
	RiceCutworm : "Cutworm",
	RiceGreenLeafhopper : "Green Leafhopper",
	RiceStemBorer : "Pink/Striped/White/Yellow Stem Borer",
	RiceBlackBug : "Rice Black Bug",
	RiceBug : "Rice Bug",
	RiceCaseworm : "Rice Caseworm",
	RiceEarCuttingCaterpillar : "Rice Ear-cutting Caterpillar",
	RiceGrainBug : "Rice Grain Bug",
	RiceLeafFolder : "Rice Leaf Folder",
	RiceWhorlMaggot : "Whorl Maggot",
	CornAphid : "Aphid",
	CornArmyworm : "Armyworm",
	CornCommonCutworm : "Common Cutworm",
	CornBorer : "Corn Borer",
	CornEarworm : "Corn Earworm",
	CornPlanthopper : "Corn Planthopper",
	CornSeedlingMaggot : "Corn Seedling Maggot",
	CornSemilooper : "Corn Semilooper",
	CornFallArmyworm : "Fall Armyworm",
	CornGrassArmyworm : "Grass Armyworm",
	CornOrientalMigratoryLocust : "Oriental Migratory Locust",
	CornTrueArmyworm : "True Armyworm",
	CornWhiteGrub : "White Grub",
	CoconutAsiaticPalmWeevil : "Asiatic Palm Weevil",
	CoconutBuffMealybug : "Coconut Buff Mealybug",
	CoconutLeafBeetle : "Coconut Leaf Beetle",
	CoconutLeafMiner : "Coconut Leaf Miner",
	CoconutSpikeMoth : "Coconut Spike Moth",
	CoconutRhinocerosBeetle : "Rhinoceros Beetle",
	CoconutSlugCaterpillar : "Slug Caterpillar",
	CoffeeBlackCitrusAphid : "Black Citrus Aphid",
	CoffeeBlackScaleInsect : "Black Scale Insect",
	CoffeeBerryBorer : "Coffee Berry Borer",
	CoffeeFlatidLeafhopper : "Flatid Leafhopper",
	CoffeeGreenScaleInsect : "Green Coffee Scale Insect",
	CoffeeLeafFolder : "Leaf Folder",
	CoffeeLeafhopper : "Coffee Leafhopper",
	CoffeeMealybug : "Mealybug",
	CoffeePulvinariaScaleInsect : "Pulvinaria Scale Insect",
	CoffeeRedStemBorer : "Red Stem Borer",
	CoffeeRedBandedThrips : "Red-banded Thrips",
	CoffeeRicaniidHopper : "Ricaniid Hopper",
	CoffeeStripedMealybug : "Striped Mealybug",
	CoffeeTussockCaterpillar : "Tussock Caterpillar",
	CoffeeWhiteStemBorer : "White Stem Borer",
	CacaoAphid : "Aphid",
	CacaoBarkBorer : "Cacao Bark Borer",
	CacaoPodBorer : "Cacao Pod Borer",
	CacaoTussockMoth : "Cacao Tussock Moth",
	CacaoLeafhopper : "Cacao Leafhopper",
	CacaoMealybug : "Mealybug",
	CacaoMiridBug : "Mirid Bug",
	CacaoRedBandedThrips : "Red Banded Thrips",
	BananaAphid : "Banana Aphid",
	BananaFlowerThrips : "Banana Flower Thrips",
	BananaWhitefly : "Whitefly",
	BananaLeafroller : "Banana Leafroller",
	BananaCoconutScaleInsect : "Coconut Scale Insect",
	BananaCommonCutworm : "Common Cutworm",
	BananaFruitScarringBeetle : "Fruit Scarring beetle",
	BananaLaceBug : "Lace Bug",
	BananaPineappleMealybug : "Pineapple Mealybug",
	BananaPseudostemBorer : "Pseudostem Borer",
	BananaRhizomeBorer : "Rhizome Borer",
	SoybeanBeanFly : "Bean Fly",
	SoybeanBlackCutworm : "Black Cutworm",
	SoybeanLeaffolder : "Leaffolder",
	SoybeanPodBorer : "Pod Borer",
	SoybeanAphid : "Soybean Aphid",
	SugarcaneArmyworm : "Armyworm",
	SugarcaneCushionScale : "Cushion Scale",
	SugarcaneMigratoryLocust : "Migratory Locust",
	SugarcanePinkMealybug : "Pink Mealybug",
	SugarcaneRootGrub : "Root Grub",
	SugarcaneAphid : "Sugarcane Aphid",
	SugarcaneBorer : "Sugarcane Borer",
	SugarcaneGrayBorer : "Sugarcane Gray Borer",
	SugarcanePinkStemBorer : "Sugarcane Pink Stem Borer",
	SugarcaneTermite : "Termite",
	SugarcaneWireworm : "Wireworm",
	SugarcaneWoolyAphid : "Wooly Aphid",
	Tomato28SpottedLadyBeetle : "28-Spotted Lady Beetle",
	TomatoFruitworm : "Fruitworm",
	TomatoLeafminer : "Leafminer",
	TomatoMelonFruitFly : "Melon Fruit Fly",
	TomatoSemilooper : "Semilooper",
	TomatoWhitefly : "Whitefly",
	TomatoRedSpiderMite : "Red Spider Mite",
	OnionArmyworm : "Onion Armyworm",
	OnionCutworm : "Cutworm",
	OnionLeafminer : "Leafminer",
	OnionThrips : "Thrips",
}

const recognizeLabel = function(label) {
	const diseaseLabel = recognizedDiseases[label];
	const pestLabel = recognizedPests[label];

	if (diseaseLabel != null && diseaseLabel != undefined) {
		return {
			recognizedLabel:true,
			recLabel:diseaseLabel,
			label:label,
		}
	} else if (pestLabel != null && pestLabel != undefined) {
		return {
			recognizedLabel:true,
			recLabel:pestLabel,
			label:label,
		}
	} else {
		return {
			recognizedLabel:false,
			recLabel:"Unrecognized Label",
			label:label,
		}
	}
}

export default recognizeLabel;