import React, { useEffect, useState } from "react";
import Stack from 'react-bootstrap/Stack';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import recognizeLabel from "../utilities/LabelRecognition";
import AnnotationComponent from "./AnnotationComponent";
import NewAnnotationComponent from "./NewAnnotationComponent";


const AnnotationListComponent = ({userData, isEditing, annotations, newAnnotations, setAnnotations, setNewAnnotations, setAnnotationTabState}) => {

	const handleTabSelect = (selectedTab) => {
		if (selectedTab == "annotationTab") {
			setAnnotationTabState(0);
		} else if (selectedTab == "archivedTab") {
			setAnnotationTabState(1);
		}
	};

	const toggleAnnotationVisibility = (id) => {
		setAnnotations((prevArray) =>
			prevArray.map((obj) => obj.id === id ? { ...obj, is_visible: !obj.is_visible } : obj)
		);
	};

	const toggleNewAnnotationVisibility = (id) => {
		setNewAnnotations((prevArray) =>
			prevArray.map((obj) => obj.id === id ? { ...obj, is_visible: !obj.is_visible } : obj)
		);
	};

	const deleteAnnotation = (id) => {
		setAnnotations((prevArray) =>
			prevArray.map((obj) => obj.id === id ? { ...obj, is_deleted: true } : obj)
		);
	};

	const deleteNewAnnotation = (idToRemove) => {
		setNewAnnotations((prevArray) => prevArray.filter((item) => item.id !== idToRemove));
	}

	const restoreAnnotation = (id) => {
		setAnnotations((prevArray) =>
			prevArray.map((obj) => obj.id === id ? { ...obj, is_deleted: false } : obj)
		);
	};

	return (
			<Tabs defaultActiveKey="annotationTab" id="ann-tab" className="mb-3" fill onSelect={handleTabSelect}>
				<Tab eventKey="annotationTab" title="Annotations">
					<Stack gap={2} className="align-items-center">
						{annotations.map((ann, i) => {
							const labelRecognition = recognizeLabel(ann.pest_disease_label)
							if (!ann.is_deleted)
								return (
									<AnnotationComponent
										annotation = {ann}
										labelRecognition = {labelRecognition}
										toggleAnnotationVisibility = {toggleAnnotationVisibility}
										deleteOrRestore = {deleteAnnotation}
										isEditing = {isEditing}/>
								)
						})}
						{newAnnotations.map((ann, i) => {
							const labelRecognition = recognizeLabel(ann.pest_disease_label)
							return (
								<NewAnnotationComponent
									userDisplayName = {userData.display_name}
									newAnnotation = {ann}
									labelRecognition = {labelRecognition}
									deleteNewAnnotation = {deleteNewAnnotation}
									toggleNewAnnotationVisibility = {toggleNewAnnotationVisibility}/>
							)
						})}
					</Stack>
				</Tab>
				<Tab eventKey="archivedTab" title="Archived">
					<Stack gap={2} className="align-items-center">
						{annotations.map((ann, i) => {
							const labelRecognition = recognizeLabel(ann.pest_disease_label)
							if (ann.is_deleted)
								return (
									<AnnotationComponent
										annotation = {ann}
										labelRecognition = {labelRecognition}
										toggleAnnotationVisibility = {toggleAnnotationVisibility}
										deleteOrRestore = {restoreAnnotation}
										isEditing = {isEditing}
									/>
								)
						})}
					</Stack>
				</Tab>
			</Tabs>
	)
}

export default AnnotationListComponent;